<template>
  <div>
    <b-row>
      <!-- start filter -->
      <b-container fluid class="filter">
        <div class="iq-card p-1">
          <div class="row">
            <div class="col-md-3 my-1">
                <input type="text" v-model="filter.search" placeholder="search by name or phone" class="form-control" @input="getAllUpgrades">
            </div>
            <div class="col-md-3 my-1">
              <select class="form-control"  v-model="filter.project" @change="getAllUpgrades()">
                <option selected disabled value="">select Project...</option>
                <option v-for="(i, key) in getUserProjects" :key="key" :value="i.id">
                  <span>{{i.en_title}}</span>
                </option>
              </select>
            </div>
            <div class="col-md-1 d-flex justify-content-center align-items-center" v-if="filter.name || filter.type
            || filter.project|| filter.expired">
              <span class="text-danger cursor-pointer" @click="resetFilter()">Clear All</span>
            </div>
          </div>
        </div>
      </b-container>
      <!-- end filter -->

      <!--start content-->
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle>
            <div class="d-flex justify-content-between w-100">
              <h4 class="card-title">Upgrades</h4>
            </div>
          </template>
          <template v-slot:body>
            <div v-if="loadTable" class="text-center">
              <b-spinner small type="grow"></b-spinner>
              Loading...
            </div>
            <b-table v-else sort-icon-left primary-key="code" striped responsive :fields="columns" :items="allMembers"
                     class="mb-0 table-borderless "  id="table-transition-example" :tbody-transition-props="transProps">
              <template v-slot:cell(created_at)="data">
                <span>{{timeConvert(data.item.created_at)}}</span>
              </template>
              <template v-slot:cell(data_before_change.created_at)="data">
                <span>{{timeConvert(data.item.data_before_change.created_at)}}</span>
              </template>
              <template v-slot:cell(member)="data">
                <router-link class="linkedReport" :to="{name: 'profile', params:{ id : data.item.member.id }}"><b-badge variant="primary">{{ data.item.member.name }}</b-badge></router-link>
              </template>
            </b-table>
            <b-pagination v-model="pagination.currentPage"
                          class="mt-3"
                          :total-rows="pagination.total"
                          align="right"
                          :per-page="pagination.per_page"
                          aria-controls="my-table"
                          @input="getAllUpgrades"
            ></b-pagination>
          </template>
        </iq-card>
      </b-col>
      <!--end content-->

    </b-row>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

import { core } from '../../../../config/pluginInit'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import services from '../../services/reports'
export default {
  name: 'allMemberReports',

  data () {
    return {
      showPhone: '',
      transProps: {
        // Transition name
        name: 'flip-list'
      },
      projects: [],
      filter: {
        name: '',
        type: '',
        project: '',
        expired: 0
      },
      dateRange: {
        startDate: '',
        endDate: ''
      },
      pagination: {
        currentPage: 1,
        per_page: 10,
        total: 6
      },
      totalMember: '',
      loadTable: true,
      columns: [
        { label: 'member', key: 'member', class: 'text-left' },
        { label: 'New Package Date', key: 'created_at', class: 'text-left' },
        { label: 'Old Package Date', key: 'data_before_change.created_at', class: 'text-left' },
        { label: 'Old Package', key: 'package_from.title', class: 'text-left' },
        { label: 'New Package', key: 'package_to.title', class: 'text-left' },
        { label: 'Upgrade Amount', key: 'fees', class: 'text-left' },
        { label: 'Old Package Cost', key: 'data_before_change.cost', class: 'text-left' },
        { label: 'New Package Cost', key: 'with_out_scope_member_package.cost', class: 'text-left' },
        { label: 'Old Package Discount', key: 'data_before_change.discount', class: 'text-left' },
        { label: 'New Package Discount', key: 'with_out_scope_member_package.discount', class: 'text-left' },
        { label: 'Old Package Net Amount', key: 'data_before_change.net', class: 'text-left' },
        { label: 'New Package Net Amount', key: 'with_out_scope_member_package.net', class: 'text-left' },
        { label: 'New Package Paid Amount', key: 'with_out_scope_member_package.pay', class: 'text-left' }
      ],
      allMembers: [],
      tablePerson: []
    }
  },
  computed: {
    ...mapGetters(['getUserProjects'])
  },
  methods: {
    showTablePackageDetails (table) {
      this.$bvModal.show('tablePerson')
      this.tablePerson = table
    },
    changeMemberStatus (id) {
      this.$store.dispatch('changeMemberStatus', id).then(res => {
        core.showSnackbar('success', res.data.message)
      }).catch(err => {
        if (err.response.data.errors) {
          for (const [key, value] of Object.entries(err.response.data.errors)) {
            this.$refs[key].setErrors(value)
          }
        } else if (err.response.data.error) {
          core.showSnackbar('error', err.response.data.error)
        }
      })
    },
    resetFilter () {
      this.filter = {
        name: '',
        project: ''
      }
      this.getAllUpgrades()
    },
    getAllUpgrades () {
      this.loadTable = true
      services.getAllUpgrades({
        pageNumber: this.pagination.currentPage,
        name: this.filter.name ? this.filter.name : '',
        projectId: this.filter.project ? this.filter.project : JSON.parse(localStorage.getItem('default_project')).id
      }).then(res => {
        this.allMembers = res.data.data
        this.pagination.currentPage = res.data.meta.current_page
        this.pagination.per_page = res.data.meta.per_page
        this.pagination.total = res.data.meta.total
      }).finally(() => {
        this.loadTable = false
      })
    }
  },
  components: {
  },
  mounted () {
    core.index()
  },
  created () {
    this.getAllUpgrades()
  }
}
</script>
<style>
.nav-link.router-link-exact-active.router-link-active{
  background: var(--iq-primary) !important;
  color: #fff !important;
}
.colorSpan{
  width: 20px;
  height: 20px;
}
[dir=ltr][mode=light] .nav-tabs {
  background: #fff !important;
  border-radius: 10px !important;

  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
}
th{
  white-space: pre !important;
  vertical-align: middle !important;
}
td{
  white-space: pre !important;
  vertical-align: middle !important;
}
</style>
